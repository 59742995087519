import { NavLink } from 'react-router-dom'
import home from '../assets/img/icons/homeicon.svg'
import history from '../assets/img/icons/history.svg'
import missed from "../assets/img/icons/missed.svg";
import logout from '../assets/img/icons/rightarrow.svg'
import profile from '../assets/img/icons/profile-pic.svg'
import LeaderBoard from '../assets/img/icons/profile-pic.svg'
// import LeaderBoard from '../assets/img/leaderboard.svg'

const Header = () => {
  const toggleMobileMenu = () => {
    document.activeElement?.blur()
  }
  return (
    <div className="headerShadow px-28 md:px-4 z-50" id="main-nav">
      <div className="container mx-auto ">
        <div className="navbar bg-base-100 px-0">
          <div className="navbar-start w-[20%] md:w-full">
            <a className="px-0" href="/home">
              {' '}
              <img
                src="/images/logo.png"
                alt="SiteLogo"
                className="h-12 w-full md:h-full"
              />
            </a>
          </div>
          <div className="navbar-end w-[80%] flex lg:hidden">
            <div className="dropdown dropdown-left">
              <label tabIndex={0} className="lg:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
              <ul
                tabIndex={0}
                className="menu menu-compact menu-vertical dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-48 text-white menu-dropdown-toggle"
              >
                <li className=" mb-2" onClick={toggleMobileMenu}>
                  <NavLink
                    to="/home"
                    className="btn-theme"
                    onClick={toggleMobileMenu}
                  >
                    <div className="">
                      <img src={home} alt="homemenuIcon" className="w-5 h-5" />
                    </div>
                    Home
                  </NavLink>
                </li>

                {/* <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink
                    to="/profile"
                    className="btn-theme"
                    onClick={toggleMobileMenu}
                  >
                    <div className="">
                      <img src={profile} alt="menuIcon" className="w-5 h-5" />
                    </div>
                    Profile
                  </NavLink>
                </li> */}

                <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink
                    to="/history"
                    className="btn-theme"
                    onClick={toggleMobileMenu}
                  >
                    <div className="">
                      <img src={history} alt="menuIcon" className="w-5 h-5" />
                    </div>
                    History
                  </NavLink>
                </li>

                <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink
                    to="/profile"
                    className="btn-theme"
                    onClick={toggleMobileMenu}
                  >
                    <div className="">
                      <img src={profile} alt="menuIcon" className="w-5 h-5" />
                    </div>
                    Profile
                  </NavLink>
                </li> 
                <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink
                    to="/leaderboard"
                    className="btn-theme"
                    onClick={toggleMobileMenu}
                  >
                    <div className="">
                      {/* <img src={LeaderBoard} alt="menuIcon" className="w-10 h-10" /> */}
                    </div>
                    Leaderboard
                  </NavLink>
                </li> 
                
                {/* <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink to="/missed-exams" className="btn-theme" onClick={toggleMobileMenu}>
                    <div className="">
                      <img src={missed} alt="menuIcon"  className="w-5 h-5"/>
                    </div>
                    Missed Exam
                  </NavLink>
                </li> */}

                {/* <li className="mb-2" onClick={toggleMobileMenu}>
                  <NavLink to="/missed-exams" className="btn-theme" onClick={toggleMobileMenu}>
                    <div className="">
                      <img src={missed} alt="menuIcon"  className="w-5 h-5"/>
                    </div>
                    Missed Exam
                  </NavLink>
                </li>
                <li className="" onClick={toggleMobileMenu}>
                  <NavLink to="/profile" className="btn-theme" onClick={toggleMobileMenu}>
                    <div className="">
                      <img src={missed} alt="menuIcon" />
                    </div>
                    Profile
                  </NavLink>
                </li>
                <li className="" onClick={toggleMobileMenu}>
                  <NavLink to="/change-password" className="btn-theme" onClick={toggleMobileMenu}>
                    <div className="">
                      <img src={missed} alt="menuIcon" />
                    </div>
                    Change Password
                  </NavLink>
                </li> */}

                <li className="">
                  <a href="/login" className="btn-theme">
                    Logout
                    <div className="">
                      <img src={logout} alt="menuIcon" />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-end w-[80%] hidden lg:flex">
            <ul className="menu menu-horizontal px-1 text-white font-bold text-sm">
              <li className="mx-2 text-center w-[8rem] h-11">
                <NavLink
                  to="/home"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={home} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Home</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li>
              {/* <li className="mx-2 text-center w-[8rem] h-11">
                <NavLink
                  to="/profile"
                  className="relative btn-theme btn-block navBtn"
                  onClick={toggleMobileMenu}
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={profile} alt="menuIcon"  className="iconImg" />
                    <span className="btnLabel">Profile</span>
                  </div>
                    <span className="circle"></span>
                </NavLink>
              </li> */}

             
              <li className="mx-2 text-center w-[8rem] h-11">
                <NavLink
                  to="/history"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={history} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">History</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li>
              <li className="mx-2 text-center w-[10rem] h-11">
                <NavLink
                  to="/profile"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={profile} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Profile</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li> 
              <li className="mx-2 text-center w-[10rem] h-11">
                <NavLink
                  to="/leaderboard"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    {/* <img src={LeaderBoard} alt="menuIcon" className="iconImg" /> */}
                    <span className="btnLabel">Leaderboard</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li> 

              {/* <li className="mx-2 text-center w-[10rem] h-11">
                <NavLink
                  to="/missed-exams"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={missed} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Missed Exam</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li> */}


              {/*          
              <li className="mx-2 text-center w-[10rem] h-11">
                <NavLink
                  to="/missed-exams"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={missed} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Missed Exam</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li>     
              <li className="mx-2 text-center w-[10rem] h-11">
                <NavLink
                  to="/profile"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={profile} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Profile</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li> 
              
              <li className="mx-2 text-center w-[13rem] h-11">
                <NavLink
                  to="/change-password"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <img src={missed} alt="menuIcon" className="iconImg" />
                    <span className="btnLabel">Change Password</span>
                  </div>
                  <span className="circle"></span>
                </NavLink>
              </li>
              */}
              <li className="mx-2 text-center w-[8rem] h-11">
                <a
                  href="/login"
                  className="relative btn-theme btn-block navBtn"
                >
                  <div className="flex items-center w-full space-x-1">
                    <span className="btnLabel">Logout</span>
                    <img src={logout} alt="menuIcon" className="iconImg" />
                  </div>
                  <span className="circle"></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Header
