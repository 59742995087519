import { Suspense, lazy, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import axios from "../../utils/axios";
import backIcon from "../../assets/img/icons/leftArrow.png";

// import QuestionWithSolution from "../../components/common/QuestionWithSolution";
import QuestionWithSolutionMcq from "../../components/common/v2/QuestionWithSolutionMcq";
import BackButton from "../../components/common/BackButton";
import authChecker from "../../utils/authChecker";
// const ExamInfoDetails = lazy(() => import("../../components/common/ExamInfoDetails"));
const ExamInfoDetails2 = lazy(() => import("../../components/common/v2/ExamInfoDetails"));
// const LogoTopCenter = lazy(() => import("../../components/LogoTopCenter"));
const McqSpecialExamInfoDetails=lazy(()=>import( './../../components/common/v2/McqSpecialExamInfoDetails')) ;


const ExamSolution = () => {
  const params = useParams();

  const [queryParams] = useState(new URLSearchParams(window.location.search));
  const [homeUrl, setHomeUrl] = useState("/");
  const [TOKEN, setTOKEN] = useState(null);
  const [examData, setExamData] = useState(null);
  const [examDetails, setExamDetails] = useState(null);
  console.log("aaa",queryParams.get('type'))
  useEffect(() => {
    setTOKEN(localStorage.getItem("STDNTTKN"));
  });
  useEffect(() => {
    if (localStorage.getItem("STDNTTKN")) {
      setHomeUrl("/home");
    }
  }, []);

  useEffect(() => {
    if (TOKEN) {
      authChecker();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN;
      if (queryParams.get('type') === "mcq") {
        Promise.all([
          axios.get('/api/student/viewsollution?examId=' + params.exam_code),
          axios.get('/api/exam/getexambyid?examId=' + params.exam_code)
        ]).then(res => {
          setExamData(res[0].data);
          if (!res[1].data) {
            window.alert("Invalid Exam or Exam deactivated");
            window.location.href = "/history";
          }
          setExamDetails(res[1].data);
        }).catch(err => {
          console.log(err);
          window.alert("Something went wrong, please inform us");
        });
      } else if (queryParams.get('type') === "mcq-both") {
        Promise.all([
          axios.get('/api/student/bothviewsollutionmcq?examId=' + params.exam_code),
          axios.get('/api/both/getbothexambyid?examId=' + params.exam_code)
        ]).then(res => {
          setExamData(res[0].data);
          if (!res[1].data) {
            window.alert("Invalid Exam or Exam deactivated");
            window.location.href = "/history";
          }
          setExamDetails(res[1].data);
        }).catch(err => {
          console.log(err);
          window.alert("Something went wrong, please inform us");
        });
      } else if (queryParams.get('type') === "mcq-special") {
        Promise.all([
          axios.get('/api/special/viewsollutionmcq?examId=' + params.exam_code),
          axios.get('/api/special/showspecialexambyidstudent?examId=' + params.exam_code)
        ]).then(res => {
          setExamData(res[0].data);
          if (!res[1].data) {
            window.alert("Invalid Exam or Exam deactivated");
            window.location.href = "/history";
          }
          setExamDetails(res[1].data);
        }).catch(err => {
          console.log(err);
          window.alert("Something went wrong, please inform us");
        });
      }else if (queryParams.get('type') === "special-mcq") {
        Promise.all([
          axios.get('/api/mcqspecialexam/viewsollutionmcq?examId=' + params.exam_code),
          axios.get('/api/mcqspecialexam/showspecialexambyidstudent?examId=' + params.exam_code)
        ]).then(res => {
          setExamData(res[0].data.subjectDetails);
          if (!res[1].data) {
            window.alert("Invalid Exam or Exam deactivated");
            window.location.href = "/history";
          }
          setExamDetails(res[1].data);
          console.log("examData",res[0].data)
          console.log("examDetails",res[1].data)
        }).catch(err => {
          console.log(err);
          window.alert("Something went wrong, please inform us");
        });
      }
    }
  }, [TOKEN, params.exam_code,queryParams]);

  return (<>
    <div className="flex flex-row bg-white text-center mb-8">
      <div className='h-[68px] mx-auto'>
        <Link to={homeUrl}>
          <img src="/images/logo.png" alt="logo" className='w-64' />
        </Link>
      </div>
    </div>
    <div className="pb-8 px-4 min-h-[90vh]">
      <div className="container max-w-5xl mx-auto">
        {/* examInoDetails */}
        <div className="mb-8 ">

          {queryParams.get("type") === "mcq" && (
            <>
              {
                examDetails ?
                  <Suspense fallback={(<Skeleton count={1} height={128}></Skeleton>)}>
                    <ExamInfoDetails2 examInfos={examDetails} variationType="mcq"/></Suspense>
                  : <Skeleton count={1} height={128}></Skeleton>
              }
              <div className="px-6 md:px-4 pt-6 md:py-4 bg-white rounded-lg border-2 border-orange-600">
                {examData ? examData.map((question, index) => (
                  <QuestionWithSolutionMcq question={question} counter={++index} key={index} type="mcq-only" />
                )) : (<Skeleton count={5} height={128}></Skeleton>)}
              </div>
            </>
          )}
          {
            queryParams.get("type") === "mcq-both" && (
              <>
                {
                  examDetails ?
                    <Suspense fallback={(<Skeleton count={1} height={128}></Skeleton>)}>
                      <ExamInfoDetails2 examInfos={examDetails} variationType="mcq" extra="no" hide="written" /></Suspense>
                    : <Skeleton count={1} height={128}></Skeleton>
                }
                {examData ? examData.map((question, index) => (
                  <div className="rounded-lg bg-white my-8 border-2 border-orange-600" key={index}>
                    <div className="px-6 md:px-4 py-6 md:py-4">
                      <QuestionWithSolutionMcq question={question} counter={++index} key={index} type="mcq-only" />
                    </div>
                  </div>
                )) :
                  (<Skeleton count={5} height={128}></Skeleton>)}
              </>
            )
          }
          {
            queryParams.get("type") === "mcq-special" &&
            <>
              {
                examDetails ?
                  <Suspense fallback={(<Skeleton count={1} height={128}></Skeleton>)}>
                    <ExamInfoDetails2 examInfos={examDetails} variationType="special" hide="written" /></Suspense>
                  : <Skeleton count={1} height={128}></Skeleton>
              }

              {examData ? examData.map((subject, index) => (
                <div className="rounded-lg bg-white my-8 border-2 border-orange-600" key={index}>
                  {index < 4 && (<><h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white mb-8 uppercase">{subject.subjectName ?? ""}</h2>
                    <div className="px-6 md:px-4 py-6 md:py-4">
                      {
                        subject.questions.map((question, idx) => (
                          <QuestionWithSolutionMcq question={question} counter={++idx} key={idx} />
                        ))
                      }
                    </div>
                  </>)}
                </div>
              )) : (<Skeleton count={5} height={128}></Skeleton>)}
            </>
          }
          {
            queryParams.get("type") === "special-mcq" &&
            <>
              {
                examDetails ?
                  <Suspense fallback={(<Skeleton count={1} height={128}></Skeleton>)}>
                    <McqSpecialExamInfoDetails examInfos={examDetails} variationType="special-mcq" hide="written" /></Suspense>
                  : <Skeleton count={1} height={128}></Skeleton>
              }

              {examData ? examData.map((subject, index) => (
                <div className="rounded-lg bg-white my-8 border-2 border-orange-600" key={index}>
                  {index < 4 && (<><h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white mb-8 uppercase">{subject.subjectName ?? ""}</h2>
                    <div className="px-6 md:px-4 py-6 md:py-4">
                      {
                        subject.questions.map((question, idx) => (
                          <QuestionWithSolutionMcq question={question} counter={++idx} key={idx} />
                        ))
                      }
                    </div>
                  </>)}
                </div>
              )) : (<Skeleton count={5} height={128}></Skeleton>)}
            </>
          }
        </div>
        <div className="pb-8 max-w-md mx-auto">
          <BackButton url="/history" title="Back to History Page" icon={backIcon} />
        </div>
      </div>
    </div>
  </>
  );
};

export default ExamSolution;
