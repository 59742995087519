import React, { useEffect, useState } from 'react'
import axios from '../utils/axios'
import toast from 'react-hot-toast'

const LeaderBoard = () => {
  const [rankingData, setRankingData] = useState([])
  const [course, setCourse] = useState('')
  const [examType, setExamType] = useState(-1)
  const [exams, setExams] = useState([])
  const [selectedExam, setSelectedExam] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    if (examType !== -1) {
      // console.log('here i am')
      axios
        .get(
          `/api/exam/exambycourse?type=${examType}&courseId=${localStorage.getItem(
            'FORCOURSE'
          )}`,
          { courseId: 'asdasdsad' }
        )
        .then(({ data }) => {
          setExams(data)
          setIsLoading(false)
          // console.log(data)
        })
    } else {
      setSelectedExam('')
    }

    if (selectedExam != '') {
      setIsLoading(true)
      if (examType === 0 || examType === 1) {
        axios
          .get(`/api/student/getallrank?examId=${selectedExam}`)
          .then(({ data }) => {
            console.log(data)
            let myData = []
            myData = data.filter(
              (d) => d.studentId === localStorage.getItem('STDNTID')
            )
            console.log('myData', myData)
            data = data.slice(0, 10)
            let allData = data
            let isAvailable
            if (myData.length > 0) {
              isAvailable = data.filter((d) => d.studentId === myData[0].studentId)
              if (isAvailable.length === 0) {
                allData.push(myData[0])
              }
            }
            // let found = 0;
            // for(let i = 0 ; i<data.length;i++ ){

            //     if(data[i].studentId===myData[0]?.studentId){
            //       found=1;
            //     }

            //   allData[i] = data[i];
            // }
            // if(found===0){
            //   allData[10] = myData[0];
            // }
            console.log(allData)

            setRankingData(allData)

            setIsLoading(false)
          })
          .catch((e) => {
            toast.error(e)
            setRankingData([])
          })
      }
      if (examType === 2) {
        axios
          .get(`/api/student/bothgetallrank?examId=${selectedExam}`)
          .then(({ data }) => {
            console.log(data)
            setRankingData(data)
            setIsLoading(false)
          })
          .catch((e) => {
            toast.error(e.response.data)
            setRankingData([])
          })
      }
      if (examType === 3) {
        axios
          .get(`/api/special/getallrank?examId=${selectedExam}`)
          .then(({ data }) => {
            console.log(data)
            setRankingData(data)
            setIsLoading(false)
          })
          .catch((e) => {
            toast.error(e.response.data)
            setRankingData([])
          })
      }
      if (examType === 4) {
        axios
          .get(`/api/mcqspecialexam/getallrank?examId=${selectedExam}`)
          .then(({ data }) => {
            console.log(data)
            setRankingData(data)
            setIsLoading(false)
          })
          .catch((e) => {
            toast.error(e.response.data)
            setRankingData([])
          })
      }
    } else {
      setRankingData([])
    }
    // axios.get('/api/exam/leaderboard')
  }, [examType, selectedExam])

  return (
    <div className="">
      <div className="px-4 lg:px-10 grid grid-cols-1 gap-5 item">
        <div className="flex justify-center items-center">
          <div className="mt-5 lg:mt-12 w-full lg:w-1/2">
            <select
              name="examType"
              id="examType"
              className="input border-black input-bordered w-full "
              onChange={(e) => {
                setExamType(parseInt(e.target.value))
              }}
              required
            >
              <option value={-1} className="text-center">
                Select Exam Type
              </option>
              <option className="text-center" value={0}>
                MCQ
              </option>
              <option className="text-center" value={1}>
                Written
              </option>
              <option className="text-center" value={2}>
                Both
              </option>
              <option className="text-center" value={3}>
                Special
              </option>
              <option className="text-center" value={4}>
                Special MCQ
              </option>
            </select>
          </div>
        </div>
        {exams.length > 0 && (
          <div className="flex justify-center items-center">
            <div className="form-control w-full lg:w-1/2">
              <select
                name="exam_list"
                id="exam_list"
                className="input w-full border-black input-bordered"
                required
                onChange={(e) => {
                  // document.getElementById('exam_list').disabled = true
                  setSelectedExam(e.target.value)
                }}
              >
                <option value="" className="text-center">
                  {' '}
                  Select Exam Name
                </option>
                {exams.length > 0 &&
                  exams.map((exam) => (
                    <option
                      className="text-center"
                      key={exam._id}
                      value={exam._id}
                    >
                      {exam.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
      </div>
      <div className="py-10 px-2 lg:px-16 flex justify-center items-center">
        <div className="overflow-x-auto w-full">
          <table className="mx-auto   w-full whitespace-nowrap rounded-lg bg-white divide-y  overflow-hidden customTable">
            {/* head */}
            <thead className="">
              <tr className="">
                {/* <th className="bg-[#F54B03] text-white text-center " >
                 <span className='ml-2'>SI</span><br/><span className='ml-2'>No.</span>
                </th> */}
                <th className="bg-[#F54B03] text-white text-center ">
                  DP
                </th>
                <th className="bg-[#F54B03] text-white text-center ">
                  Name
                </th>
                <th className="bg-[#F54B03] text-white text-center ">
                  Marks
                </th>
                <th className="bg-[#F54B03] text-white text-center ">
                  Position
                </th>
              </tr>
            </thead>
            <tbody>
              {rankingData.length > 0 &&
                rankingData.map((data, idx) => (
                  <tr
                    key={idx}
                    className={` ${
                      idx === 0
                        ? 'bg-blue-300'
                        : idx === 1
                        ? 'bg-green-500'
                        : idx === 2
                        ? 'bg-teal-400'
                        : 'even:bg-table-row-even odd:bg-table-row-odd '
                    } text-center w-full  
                    ${
                      data.studentId === localStorage.getItem('STDNTID')
                        ? 'animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-red-400  bg-clip-text text-transparent text-3xl font-black'
                        : 'text-color-one text-2xl'
                    } font-bold `}
                  >
                    {/* <td>
                      <span className='ml-2'> {idx+1}</span>
                    </td> */}
                    <td>
                      {/* {data.displayPicture ? (
                      <div className="flex justify-center items-center">
                        <img
                          className="w-48 h-48 rounded-full"
                          src={`${process.env.REACT_APP_FILES_HOST}/${data.displayPicture}`}
                          alt="pp"
                        />
                      </div>
                    ) : (
                      <div className="w-48 h-48 bg-gray-400 mx-auto rounded-full shadow-2xl my-1 flex items-center justify-center text-indigo-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-24 w-24 "
                          viewBox="0 0 20 20"
                          fill="#F54B03"
                        >
                          {' '}
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                     */}
                      <div className="w-8 h-8 bg-gray-400 mx-auto rounded-full shadow-2xl my-1 flex items-center justify-center text-indigo-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 "
                          viewBox="0 0 20 20"
                          fill="#F54B03"
                        >
                          {' '}
                          <path
                            fillRule="evenodd"
                            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </td>
                    <td>{
                      
                      data.studentName.split(' ').length>2?  data.studentName.split(' ')[0]+' '+data.studentName.split(' ')[1] : data.studentName
                      }</td>
                    <td>{data.totalObtainedMarks}</td>
                    <td>{data.rank}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className=" shadow-md rounded-md p-4 mx-auto max-w-sm">
        <h2 className="text-xl font-semibold mb-4 mt-10">Top Users</h2>
        <ul className="">
          <li className="flex items-center justify-between py-2 border-b border-gray-300">
            <div className="flex items-center">
              <span className="text-lg font-semibold mr-4">1</span>
              <img
                src="https://via.placeholder.com/48"
                alt="User Avatar"
                className="w-8 h-8 rounded-full mr-4"
              />
              <span className="text-gray-800 font-semibold">John Doe</span>
            </div>
            <span className="text-green-500 font-semibold">1000 Points</span>
          </li>
          <li className="flex items-center justify-between py-2 border-b border-gray-300">
            <div className="flex items-center">
              <span className="text-lg font-semibold mr-4">2</span>
              <img
                src="https://via.placeholder.com/48"
                alt="User Avatar"
                className="w-8 h-8 rounded-full mr-4"
              />
              <span className="text-gray-800 font-semibold">Jane Doe</span>
            </div>
            <span className="text-green-500 font-semibold">950 Points</span>
          </li>
          <li className="flex items-center justify-between py-2 border-b border-gray-300">
            <div className="flex items-center">
              <span className="text-lg font-semibold mr-4">3</span>
              <img
                src="https://via.placeholder.com/48"
                alt="User Avatar"
                className="w-8 h-8 rounded-full mr-4"
              />
              <span className="text-gray-800 font-semibold">Bob Smith</span>
            </div>
            <span className="text-green-500 font-semibold">850 Points</span>
          </li>
          <li className="flex items-center justify-between py-2 border-b border-gray-300">
            <div className="flex items-center">
              <span className="text-lg font-semibold mr-4">4</span>
              <img
                src="https://via.placeholder.com/48"
                alt="User Avatar"
                className="w-8 h-8 rounded-full mr-4"
              />
              <span className="text-gray-800 font-semibold">Alice Smith</span>
            </div>
            <span className="text-green-500 font-semibold">800 Points</span>
          </li>
          <li className="flex items-center justify-between py-2">
            <div className="flex items-center">
              <span className="text-lg font-semibold mr-4">5</span>
              <img
                src="https://via.placeholder.com/48"
                alt="User Avatar"
                className="w-8 h-8 rounded-full mr-4"
              />
              <span className="text-gray-800 font-semibold">Joe Johnson</span>
            </div>
            <span className="text-green-500 font-semibold">750 Points</span>
          </li>
        </ul>
      </div> */}
    </div>
  )
}

export default LeaderBoard
