import { useState, useEffect, Suspense, lazy } from 'react'
import { Link } from 'react-router-dom'
import axios from '../../utils/axios'

import Skeleton from 'react-loading-skeleton'
import { useParams, useNavigate } from 'react-router-dom'
import ExamInfoDetails from '../../components/common/v2/ExamInfoDetails'
import RightArrow from '../../components/common/svg/RightArrow'
import CountDownTwo from '../../components/common/CountDownTwo'
import Question from '../../components/common/Question'
import QuestionSpecial from '../../components/common/v2/QuestionSpecial'
import authChecker from '../../utils/authChecker'
import UploadAnswerSpecialNew from '../../components/common/v2/UploadAnswerSpecialNew'
import UploadAnswerNew from '../../components/common/UploadAnswerNew'
import McqQuestionSpecial from '../../components/common/v2/McqQuestionSpecial'

const Modal = lazy(() => import('../../components/common/v2/ResultSummery'))
const Modal2 = lazy(() => import('../../components/common/Modal'))
const moment = require('moment')

const types = {
  1: 'daily exam',
  2: 'weekly exam',
  3: 'monthly exam',
}

const OngoingExam = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  const [homeUrl, setHomeUrl] = useState('/')
  const [TOKEN, setTOKEN] = useState(null)
  const [timer, setTimer] = useState(0)
  const [examData, setExamData] = useState(null)
  const [runningData, setRunningData] = useState(null)
  const [examVariation, setExamVariation] = useState('mcq')
  const [error, setError] = useState(null)
  const [result, setResult] = useState(null)
  const [writtenStarted, setWrittenStarted] = useState(false)

  const [examDetails, setExamDetails] = useState()
  const [optionalSubjects, setOptionalSubjects] = useState()

  useEffect(() => {
    setTOKEN(localStorage.getItem('STDNTTKN'))
  },[])

  useEffect(() => {
    if (localStorage.getItem('STDNTTKN')) {
      setHomeUrl('/home')
    }
  }, [])

  useEffect(() => {
    if (TOKEN) {
      authChecker()
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN

      if (params.exam_variation === 'mcq') {
        axios
          .get('/api/student/examcheckmiddleware?eId=' + params.exam_code)
          .then(({ data }) => {
            if (data === 'assign') {
              navigate(
                `/exam/${params.exam_code}/rules/${params.exam_variation}`
              )
            } else if (data === 'running') {
              axios
                .get('/api/student/getrunningdata?eId=' + params.exam_code)
                .then(({ data }) => {
                  setExamData(data.examData)

                  if (!localStorage.getItem(params.exam_code)) {
                    setRunningData(data.questionData)
                    let _answers = data.questionData.map(
                      (d) => d.answeredOption
                    )
                    localStorage.setItem(
                      params.exam_code,
                      JSON.stringify(_answers)
                    )
                  } else {
                    let _local = JSON.parse(
                      localStorage.getItem(params.exam_code)
                    )
                    data.questionData.map((d, index) => {
                      if (_local[index] !== '-1') {
                        d.answeredOption = _local[index]
                      }
                    })
                    setRunningData(data.questionData)
                  }
                  let maxDuration = moment
                    .utc(data.timeData.endTine)
                    .subtract(6, 'h')
                  let lastTime = moment(maxDuration)
                  setTimer(lastTime.valueOf())

                  if (lastTime.isAfter(data.examData.endTime)) {
                    maxDuration = moment
                      .utc(data.timeData.endTine)
                      .diff(data.timeData.startTime, 'm')
                    lastTime = moment(
                      moment.utc(data.timeData.startTime).add(maxDuration, 'm')
                    )
                    setTimer(lastTime.valueOf())
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else {
              if (localStorage.getItem(params.exam_code)) {
                localStorage.removeItem(params.exam_code)
              }
              setError({ title: "Can't start now", message: 'Exam has ended' })
              let checkedModal = document.getElementById('my-modal-3')
              checkedModal.checked = true
              checkedModal.addEventListener('change', (e) => {
                if (e.target.checked === false) {
                  navigate('/history')
                }
              })
            }
          })
      } else if (params.exam_variation === 'written') {
        axios
          .get(
            '/api/student/writtenexamcheckmiddleware?examId=' + params.exam_code
          )
          .then(({ data }) => {
            if (data === 'assign') {
              navigate(
                `/exam/${params.exam_code}/rules/${params.exam_variation}`
              )
            } else if (data === 'running') {
              axios
                .get('/api/student/runningwritten?examId=' + params.exam_code)
                .then(({ data }) => {
                  setExamData(data)
                  setRunningData(data)
                  let maxDuration = moment
                    .utc(data.studExamEndTime)
                    .subtract(6, 'h')
                  let lastTime = moment(maxDuration)
                  setTimer(lastTime.valueOf())

                  if (lastTime.isAfter(data.studExamEndTime)) {
                    maxDuration = moment
                      .utc(data.examEndTime)
                      .diff(data.studExamStartTime, 'm')
                    lastTime = moment(
                      moment.utc(data.examEndTime).add(maxDuration, 'm')
                    )
                    setTimer(lastTime.valueOf())
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else {
              setError({ title: "Can't start now", message: 'Exam has ended' })
              let checkedModal = document.getElementById('my-modal-3')
              checkedModal.checked = true
              checkedModal.addEventListener('change', (e) => {
                if (e.target.checked === false) {
                  navigate('/history')
                }
              })
            }
          })
      } else if (params.exam_variation === 'mcq-written') {
        axios
          .get('/api/student/bothexamcheckmiddleware?eId=' + params.exam_code)
          .then(({ data }) => {
            if (data === 'Mcq assign') {
              navigate(
                `/exam/${params.exam_code}/rules/${params.exam_variation}`
              )
            } else if (data === 'Mcq running') {
              axios
                .get(
                  '/api/student/bothgetrunningdatamcq?eId=' + params.exam_code
                )
                .then(({ data }) => {
                  setExamData(data)
                  setRunningData(data.questionData)
                  let maxDuration = moment
                    .utc(data.timeData.endTime)
                    .subtract(6, 'h')
                  let lastTime = moment(maxDuration)
                  setTimer(lastTime.valueOf())

                  if (lastTime.isAfter(data.timeData.endTime)) {
                    maxDuration = moment
                      .utc(data.examData.endTime)
                      .diff(data.timeData.startTime, 'm')
                    lastTime = moment(
                      moment.utc(data.examData.endTime).add(maxDuration, 'm')
                    )
                    setTimer(lastTime.valueOf())
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else if (data === 'Mcq ended') {
              axios
                .get(
                  '/api/student/bothassignquestionwritten?examId=' +
                    params.exam_code
                )
                .then(() => {
                  setExamVariation('written')
                  axios
                    .get(
                      '/api/student/bothrunningwritten?examId=' +
                        params.exam_code
                    )
                    .then(({ data }) => {
                      setWrittenStarted(true)
                      setExamData(data)
                      setRunningData(data)
                      let maxDuration = moment
                        .utc(data.studExamEndTime)
                        .subtract(6, 'h')
                      let lastTime = moment(maxDuration)
                      setTimer(lastTime.valueOf())

                      if (lastTime.isAfter(data.studExamEndTime)) {
                        maxDuration = moment
                          .utc(data.examEndTime)
                          .diff(data.studExamStartTime, 'm')
                        lastTime = moment(
                          moment.utc(data.examEndTime).add(maxDuration, 'm')
                        )
                        setTimer(lastTime.valueOf())
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
            } else {
              setError({ title: "Can't start now", message: 'Exam has ended' })
              let checkedModal = document.getElementById('my-modal-3')
              checkedModal.checked = true
              checkedModal.addEventListener('change', (e) => {
                if (e.target.checked === false) {
                  navigate('/history')
                }
              })
            }
          })
      } else if (params.exam_variation === 'mcq-special') {
        axios
          .get(
            '/api/mcqspecialexam/examcheckmiddleware?examId=' + params.exam_code
          )
          .then(({ data }) => {
            if (data === 'Mcq assign') {
              navigate(
                `/exam/${params.exam_code}/rules/${params.exam_variation}`
              )
            } else if (data === 'Mcq running') {
              axios
                .get(
                  '/api/mcqspecialexam/getrunningdatamcq?examId=' +
                    params.exam_code
                )
                .then(({ data }) => {
                  console.log(data);
                  setExamVariation('mcq')
                  setExamData(data)
                  setRunningData(data.data)
                  let maxDuration = moment
                    .utc(data.examDet.studExamEndTime)
                    .subtract(6, 'h')
                  let lastTime = moment(maxDuration)
                  setTimer(lastTime.valueOf())

                  if (lastTime.isAfter(data.examDet.studExamEndTime)) {
                    maxDuration = moment
                      .utc(data.examDet.studExamEndTime)
                      .diff(data.examDet.studExamStartTime, 'm')
                    lastTime = moment(
                      moment
                        .utc(data.examDet.studExamEndTime)
                        .add(maxDuration, 'm')
                    )
                    setTimer(lastTime.valueOf())
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else if (data === 'Mcq ended') {
              axios
                .get(
                  '/api/mcqspecialexam/showspecialexambyidstudent?examId=' +
                    params.exam_code
                )
                .then(({ data }) => {
                  setError({
                    title: "Can't start now",
                    message: 'Exam has ended',
                  })
                  let checkedModal = document.getElementById('my-modal-3')
                  checkedModal.checked = true
                  checkedModal.addEventListener('change', (e) => {
                    if (e.target.checked === false) {
                      navigate('/history')
                    }
                  })
                })
                .catch((err) => {
                  window.alert('Something went wrong, please inform us')
                  console.log(err)
                })
              // axios.get('/api/special/assignquestionwritten?examId=' + params.exam_code)
              //   .then(() => {
              //     axios.get('/api/student/bothrunningwritten?examId=' + params.exam_code)
              //       .then(({ data }) => {
              //         setExamVariation("written");
              //         setExamData(data);
              //         setRunningData(data);
              //         let maxDuration = moment.utc(data.studExamEndTime);
              //         let lastTime = moment(maxDuration);
              //         setTimer(lastTime.valueOf());

              //         if (lastTime.isAfter(data.studExamEndTime)) {
              //           maxDuration = moment.utc(data.examEndTime).diff(data.studExamStartTime, "m");
              //           lastTime = moment(moment.utc(data.examEndTime).add(maxDuration, "m"));
              //           setTimer(lastTime.valueOf());
              //         }
              //       }).catch(err => {
              //         console.log(err);
              //       });
              //   });
            } else {
              setError({ title: "Can't start now", message: 'Exam has ended' })
              let checkedModal = document.getElementById('my-modal-3')
              checkedModal.checked = true
              checkedModal.addEventListener('change', (e) => {
                if (e.target.checked === false) {
                  navigate('/history')
                }
              })
            }
          })
      } else {
        axios
          .get('/api/special/examcheckmiddleware?examId=' + params.exam_code)
          .then(({ data }) => {
            if (data === 'Mcq assign') {
              navigate(
                `/exam/${params.exam_code}/rules/${params.exam_variation}`
              )
            } else if (data === 'Mcq running') {
              axios
                .get(
                  '/api/special/getrunningdatamcq?examId=' + params.exam_code
                )
                .then(({ data }) => {
                  setExamVariation('mcq')
                  setExamData(data)
                  setRunningData(data.data)
                  let maxDuration = moment
                    .utc(data.examDet.studExamEndTime)
                    .subtract(6, 'h')
                  let lastTime = moment(maxDuration)
                  setTimer(lastTime.valueOf())

                  if (lastTime.isAfter(data.examDet.studExamEndTime)) {
                    maxDuration = moment
                      .utc(data.examDet.studExamEndTime)
                      .diff(data.examDet.studExamStartTime, 'm')
                    lastTime = moment(
                      moment
                        .utc(data.examDet.studExamEndTime)
                        .add(maxDuration, 'm')
                    )
                    setTimer(lastTime.valueOf())
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else if (data === 'Mcq ended') {
              axios
                .get(
                  '/api/special/showspecialexambyidstudent?examId=' +
                    params.exam_code
                )
                .then(({ data }) => {
                  axios
                    .get(
                      `/api/special/assignquestionwritten?examId=${params.exam_code}&subjectId1=${data.subjectsId[0]}&subjectId2=${data.subjectsId[1]}&subjectId3=${data.subjectsId[2]}&subjectId4=${data.subjectsId[3]}`
                    )
                    .then(({ data }) => {
                      setWrittenStarted(true)
                      setExamVariation('written')
                      axios
                        .get(
                          `/api/special/runningwritten?examId=${params.exam_code}`
                        )
                        .then(({ data }) => {
                          setExamDetails(data)

                          // setExamData(data);
                          const questions = []
                          for (let i = 0; i < 4; i++) {
                            questions.push(data[i])
                          }
                          setRunningData(questions)

                          let maxDuration = moment
                            .utc(data[5].studExamEndTime)
                            .subtract(6, 'h')
                          let lastTime = moment(maxDuration)
                          setTimer(lastTime.valueOf())

                          if (lastTime.isAfter(data[6].examEndTime)) {
                            maxDuration = moment
                              .utc(data[6].examEndTime)
                              .diff(data[4].studExamStartTime, 'm')
                            lastTime = moment(
                              moment
                                .utc(data[4].studExamStartTime)
                                .add(maxDuration, 'm')
                            )
                            setTimer(lastTime.valueOf())
                          }
                        })
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
                .catch((err) => {
                  window.alert('Something went wrong, please inform us')
                  console.log(err)
                })
              // axios.get('/api/special/assignquestionwritten?examId=' + params.exam_code)
              //   .then(() => {
              //     axios.get('/api/student/bothrunningwritten?examId=' + params.exam_code)
              //       .then(({ data }) => {
              //         setExamVariation("written");
              //         setExamData(data);
              //         setRunningData(data);
              //         let maxDuration = moment.utc(data.studExamEndTime);
              //         let lastTime = moment(maxDuration);
              //         setTimer(lastTime.valueOf());

              //         if (lastTime.isAfter(data.studExamEndTime)) {
              //           maxDuration = moment.utc(data.examEndTime).diff(data.studExamStartTime, "m");
              //           lastTime = moment(moment.utc(data.examEndTime).add(maxDuration, "m"));
              //           setTimer(lastTime.valueOf());
              //         }
              //       }).catch(err => {
              //         console.log(err);
              //       });
              //   });
            } else {
              setError({ title: "Can't start now", message: 'Exam has ended' })
              let checkedModal = document.getElementById('my-modal-3')
              checkedModal.checked = true
              checkedModal.addEventListener('change', (e) => {
                if (e.target.checked === false) {
                  navigate('/history')
                }
              })
            }
          })
      }
    }
  }, [TOKEN, params.exam_code, params.exam_variation, navigate])

  /**
   * Store per question answer option to database
   */
  const handleQuestionSelect = (event, activeQuestion) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/student/updateanswer', {
        examId: params.exam_code,
        questionIndexNumber: activeQuestion,
        optionIndexNumber: event.target.value,
      })
      .then(({ data }) => {
        if (data === 'Ok') {
          let all = runningData
          all[activeQuestion].answeredOption = event.target.value
          setRunningData([...all])
        } else {
          setError({ title: 'Error', message: data })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          setTimeout(function () {
            checkedModal.checked = false
            window.location.reload()
          }, 5000)
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              window.location.reload()
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  /**
   * Store answer selection per questions inside localStorage
   */
  const handleQuestionSelectLocal = (event, activeQuestion) => {
    let all = runningData
    all[activeQuestion].answeredOption = event.target.value
    setRunningData([...all])

    let _local = JSON.parse(localStorage.getItem(params.exam_code))
    _local[activeQuestion] = event.target.value
    localStorage.setItem(params.exam_code, JSON.stringify(_local))
  }
  const handleQuestionSelectBoth = (event, activeQuestion) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/student/bothupdateanswermcq', {
        examId: params.exam_code,
        questionIndexNumber: activeQuestion,
        optionIndexNumber: event.target.value,
      })
      .then(({ data }) => {
        if (data === 'Ok') {
          let all = runningData
          all[activeQuestion].answeredOption = event.target.value
          setRunningData([...all])
        } else {
          setError({ title: 'Error', message: data })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          setTimeout(function () {
            checkedModal.checked = false
            window.location.reload()
          }, 5000)
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              window.location.reload()
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleQuestionSelectSpecialMcq = (
    event,
    activeQuestion,
    subjectId,
    subjectIndex
  ) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/special/updateanswer', {
        examId: params.exam_code,
        subjectId: subjectId,
        questionIndexNumber: activeQuestion,
        optionIndexNumber: event.target.value,
      })
      .then(({ data }) => {
        if (data === 'Ok') {
          let all = runningData
          all[subjectIndex].answeredOptions[activeQuestion] = event.target.value
          setRunningData([...all])
        } else {
          setError({ title: 'Error', message: data })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          setTimeout(function () {
            checkedModal.checked = false
            window.location.reload()
          }, 5000)
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              window.location.reload()
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleQuestionSelectMcqSpecial = (
    event,
    activeQuestion,
    subjectId,
    subjectIndex
  ) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/mcqspecialexam/updateanswer', {
        examId: params.exam_code,
        subjectId: subjectId,
        questionIndexNumber: activeQuestion,
        optionIndexNumber: event.target.value,
      })
      .then(({ data }) => {
        if (data === 'Ok') {
          // console.log("dhukeche");
          let all = runningData
          console.log("previous running Data: ", all);
          all[subjectIndex].answeredOptions[activeQuestion] = event.target.value
          console.log("current running data:",all);
          setRunningData([...all])
        } else {
          setError({ title: 'Error', message: data })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          setTimeout(function () {
            checkedModal.checked = false
            window.location.reload()
          }, 5000)
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              window.location.reload()
            }
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmit = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    if (params.exam_variation === 'mcq') {
      axios
        .post('/api/student/submitanswer?eId=' + params.exam_code)
        .then((res) => {
          // setResult({ bgColor: 'none', result: data, customWidth: 'max-w-4xl' });
          // let checkedModal = document.getElementById('my-modal-4')
          // checkedModal.checked = true;
          // checkedModal.addEventListener('change', (e) => {
          //   if (e.target.checked === false) {
          //     navigate('/history');
          //   }
          // });
          if (res.status === 201) {
            setError({
              title: 'Late submission',
              message: 'Better luck next time',
              showHtml: true,
            })
          } else {
            setError({
              title: 'Exam Completed',
              message:
                'MCQ exam has been submitted<br/>Please wait for the result to be published',
              showHtml: true,
            })
          }
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              localStorage.removeItem(params.exam_code)
              navigate('/history')
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (params.exam_variation === 'written') {
      axios
        .post('/api/student/submitwritten?examId=' + params.exam_code)
        .then(({ data }) => {
          // setResult({ bgColor: 'none', result: data, customWidth: 'max-w-4xl' });
          // let checkedModal = document.getElementById('my-modal-4')
          // checkedModal.checked = true;
          // checkedModal.addEventListener('change', (e) => {
          //   if (e.target.checked === false) {
          //     navigate('/history');
          //   }
          // });

          localStorage.removeItem(`${params.exam_code}data`)
          setError({
            title: 'Exam Completed',
            message:
              'Written exam has been submitted<br/>Please wait for the result to be published',
            showHtml: true,
          })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              navigate('/history')
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleSubmitLocal = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    if (params.exam_variation === 'mcq') {
      let _local = JSON.parse(localStorage.getItem(params.exam_code))
      axios
        .post('/api/student/submitanswer?eId=' + params.exam_code, {
          answeredOptions: _local,
        })
        .then((res) => {
          // setResult({ bgColor: 'none', result: data, customWidth: 'max-w-4xl' });
          // let checkedModal = document.getElementById('my-modal-4')
          // checkedModal.checked = true;
          // checkedModal.addEventListener('change', (e) => {
          //   if (e.target.checked === false) {
          //     navigate('/history');
          //   }
          // });
          localStorage.removeItem(params.exam_code)
          if (res.status === 201) {
            setError({
              title: 'Late submission',
              message: 'Better luck next time',
              showHtml: true,
            })
          } else {
            setError({
              title: 'Exam Completed',
              message:
                'MCQ exam has been submitted<br/>Please wait for the result to be published',
              showHtml: true,
            })
          }
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              navigate('/history')
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    } else if (params.exam_variation === 'written') {
      axios
        .post('/api/student/submitwritten?examId=' + params.exam_code)
        .then(({ data }) => {
          // setResult({ bgColor: 'none', result: data, customWidth: 'max-w-4xl' });
          // let checkedModal = document.getElementById('my-modal-4')
          // checkedModal.checked = true;
          // checkedModal.addEventListener('change', (e) => {
          //   if (e.target.checked === false) {
          //     navigate('/history');
          //   }
          // });
          localStorage.removeItem(`${params.exam_code}data`)
          setError({
            title: 'Exam Completed',
            message:
              'Written exam has been submitted<br/>Please wait for the result to be published',
            showHtml: true,
          })
          let checkedModal = document.getElementById('my-modal-3')
          checkedModal.checked = true
          checkedModal.addEventListener('change', (e) => {
            if (e.target.checked === false) {
              navigate('/history')
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleSubmitBothMcq = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/student/bothsubmitanswermcq?eId=' + params.exam_code)
      .then(({ data }) => {
        window.location.reload()
        // setError({ title: "Success", message: "MCQ Exam has been Finished Successfully, Best of Luck for Written Part" });
        // let checkedModal = document.getElementById('my-modal-3')
        // checkedModal.checked = true;
        // checkedModal.addEventListener('change', (e) => {
        //   if (e.target.checked === false) {
        //     window.location.reload();
        //   }
        // });
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleSubmitBothWritten = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/student/bothsubmitwritten?examId=' + params.exam_code)
      .then(({ data }) => {
        localStorage.removeItem(`${params.exam_code}data`)
        setError({
          title: 'Exam Completed',
          message:
            'Both MCQ and Written part has been submitted<br/>Please wait for the result to be published',
          showHtml: true,
        })
        let checkedModal = document.getElementById('my-modal-3')
        checkedModal.checked = true
        checkedModal.addEventListener('change', (e) => {
          if (e.target.checked === false) {
            navigate('/history')
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSubmitSpecialMcq = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/special/submitanswermcq', { eId: params.exam_code })
      .then(({ data }) => {
        window.location.reload()
        // setError({ title: "Success", message: "MCQ Exam has been Finished Successfully, Best of Luck for Written Part" });
        // let checkedModal = document.getElementById('my-modal-3')
        // checkedModal.checked = true;
        // checkedModal.addEventListener('change', (e) => {
        //   if (e.target.checked === false) {
        //     window.location.reload();
        //   }
        // });
      })
      .catch((err) => {
        console.log(err)
        window.alert('Something went wrong!')
      })
  }
  const handleSubmitMcqSpecial = () => {
    console.log("ay eidike")
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
  
    axios
      .post('/api/mcqspecialexam/submit', { eId: params.exam_code })
      .then((res) => {
       
        if (res.status !== 201) {
          setError({
            title: 'Late submission',
            message: 'Better luck next time',
            showHtml: true,
          })
        } else {

          setError({
            title: 'Exam Completed',
            message:
              'Exam has been submitted<br/>Please wait for the result to be published',
            showHtml: true,
          })
        }
        let checkedModal = document.getElementById('my-modal-3')
        checkedModal.checked = true
        checkedModal.addEventListener('change', (e) => {
          if (e.target.checked === false) {
            navigate('/history')
          }
        })
      })
      .catch((err) => {
        console.log(err)
        window.alert('Something went wrong!')
      })
  }
  const handleSubmitSpecialWritten = () => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + TOKEN
    axios
      .post('/api/special/submitwritten?examId=' + params.exam_code)
      .then(({ data }) => {
        localStorage.removeItem(`${params.exam_code}data`)
        setError({
          title: 'Exam Completed',
          message:
            'Both MCQ and Written part has been submitted<br/>Please wait for the result to be published',
          showHtml: true,
        })
        let checkedModal = document.getElementById('my-modal-3')
        checkedModal.checked = true
        checkedModal.addEventListener('change', (e) => {
          if (e.target.checked === false) {
            navigate('/history')
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="flex flex-row bg-white text-center mb-8">
        <div className="h-[68px] mx-auto">
          <Link to={homeUrl}>
            <img src="/images/logo.png" alt="logo" className="w-64" />
          </Link>
        </div>
      </div>
      {writtenStarted && (
        <div className="mx-auto mb-8 max-w-md text-center text-orange-500 font-bold p-2 border-2 border-orange-400 relative rounded-lg">
          MCQ Exam has been Finished Successfully
          <br />
          Best of Luck for the Written Part
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={4}
            stroke="currentColor"
            className="absolute top-[50%] -translate-y-[50%] -right-[12px] text-white bg-green-400 rounded-full w-6 p-[0.2rem] h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </div>
      )}
      <div className="px-28 md:px-4">
        <div className="pb-8 container mx-auto max-w-4xl">
          {params.exam_variation === 'mcq' && (
            <div className="bg-white rounded-xl">
              {examData && (
                <div className="flex btn-theme rounded-t-xl py-1 justify-center">
                  <span className="text-2xl font-bold capitalize text-white">
                    {types[examData.examType]}
                  </span>
                </div>
              )}
              {examData && timer > 0 && (
                <div className="bg-white sticky top-0 z-50">
                  <CountDownTwo
                    date={timer}
                    completedAction={handleSubmitLocal}
                    autoSubmit={false}
                  />
                </div>
              )}
              {examData && (
                <div className="px-4">
                  <ExamInfoDetails examInfos={examData} variationType="mcq" />
                </div>
              )}
              <div className="p-6">
                {runningData ? (
                  runningData.map((question, index) => (
                    <Question
                      question={question}
                      index={index}
                      key={index}
                      handleQuestionSelect={handleQuestionSelectLocal}
                    />
                  ))
                ) : (
                  <Skeleton count={5} height={128}></Skeleton>
                )}
                {/* submit ans */}
                <div className="text-center my-4 max-w-sm mx-auto">
                  <button
                    className="btn-hover text-white pr-2 py-3 rounded-md"
                    onClick={handleSubmitLocal}
                  >
                    Submit Answer
                    <span className="btn-hover_icon">
                      <RightArrow />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {params.exam_variation === 'written' && (
            <div className="bg-white rounded-xl">
              {examData && (
                <div className="flex btn-theme rounded-t-xl py-1 justify-center">
                  <span className="text-2xl font-bold capitalize text-white">
                    {types[examData.examType]}
                  </span>
                </div>
              )}
              {examData && timer > 0 && (
                <div className="bg-white sticky top-0 z-50">
                  <CountDownTwo date={timer} completedAction={handleSubmit} />
                </div>
              )}
              {examData && (
                <div className="py-0 mx-6 flex justify-between border-2 border-gray-500 rounded-xl">
                  <span className="pl-2">
                    Written:{' '}
                    <span className="font-bold text-title-2">
                      {examData.totalMarks}
                    </span>
                  </span>
                  <span className="pr-2">
                    Time:{' '}
                    <span className="font-bold text-title-2">
                      {examData.duration} Min
                    </span>
                  </span>
                </div>
              )}
              <div className="p-6">
                {runningData ? (
                  <div className="mb-6">
                    <div className="mb-3 rounded-lg shadow-[0px_0px_4px_2px_rgba(275,75,0,0.75)]">
                      <img
                        className="rounded-lg"
                        src={`${process.env.REACT_APP_FILES_HOST}/${runningData.questionILink}`}
                        alt="wrtten"
                      />
                    </div>
                    <div className="mt-8 flex flex-col justify-center items-center self-center">
                      {runningData.marksPerQuestions.map((d, index) => (
                        <UploadAnswerNew
                          uploadEndpoint="/api/student/submitstudentscript"
                          exam_code={runningData.examId}
                          totalQuestions={runningData.totalQuestions}
                          marksPerQuestions={d}
                          index={index + 1}
                          token={TOKEN}
                          key={index}
                        ></UploadAnswerNew>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Skeleton count={5} height={128}></Skeleton>
                )}
                <div className="text-center my-4 max-w-sm mx-auto">
                  <button
                    className="btn-hover text-white pr-2 py-3 rounded-md"
                    onClick={handleSubmit}
                  >
                    Submit Answer
                    <span className="btn-hover_icon">
                      <RightArrow />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {params.exam_variation === 'mcq-written' &&
            examVariation === 'mcq' && (
              <div className="bg-white rounded-xl">
                {examData && (
                  <div className="flex btn-theme rounded-t-xl py-1 justify-center">
                    <span className="text-2xl font-bold capitalize text-white">
                      {types[examData.examData.examType]}
                    </span>
                  </div>
                )}
                {examData && timer > 0 && (
                  <div className="bg-white sticky top-0 z-50">
                    <CountDownTwo
                      date={timer}
                      completedAction={handleSubmitBothMcq}
                    />
                  </div>
                )}
                {examData && (
                  <ExamInfoDetails
                    examInfos={examData.examData}
                    variationType={examVariation}
                    extra="no"
                  />
                )}
                <div className="p-6">
                  {runningData ? (
                    runningData.map((question, index) => (
                      <Question
                        question={question}
                        index={index}
                        key={index}
                        handleQuestionSelect={handleQuestionSelectBoth}
                      />
                    ))
                  ) : (
                    <Skeleton count={5} height={128}></Skeleton>
                  )}
                  <div className="text-center my-4 max-w-sm mx-auto">
                    <button
                      className="btn-hover text-white pr-2 py-3 rounded-md"
                      onClick={handleSubmitBothMcq}
                    >
                      Submit Answer
                      <span className="btn-hover_icon">
                        <RightArrow />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          {params.exam_variation === 'mcq-written' &&
            examVariation === 'written' && (
              <div className="mt-4 bg-white rounded-xl">
                {/* {examData && (<div className="flex btn-theme rounded-t-xl py-1 justify-center">
                  <span className="text-2xl font-bold capitalize text-white">{types[examData.examType]}</span>
                </div>)} */}
                <div className="flex btn-theme rounded-t-xl py-1 justify-center">
                  <span className="text-2xl font-bold capitalize text-white">
                    Written Exam
                  </span>
                </div>
                {examData && timer > 0 && (
                  <div className="bg-white sticky top-0 z-50">
                    <CountDownTwo
                      date={timer}
                      completedAction={handleSubmitBothWritten}
                    />
                  </div>
                )}
                {examData && (
                  <div className="py-0 mx-6 flex justify-between border-2 border-gray-500 rounded-xl">
                    <span className="pl-2">
                      Written:{' '}
                      <span className="font-bold text-title-2">
                        {examData.totalMarks}
                      </span>
                    </span>
                    <span className="pr-2">
                      Time:{' '}
                      <span className="font-bold text-title-2">
                        {examData.duration} Min
                      </span>
                    </span>
                  </div>
                )}
                <div className="p-6">
                  {runningData ? (
                    <div className="mb-6">
                      <div className="mb-3 rounded-lg shadow-[0px_0px_4px_2px_rgba(275,75,0,0.75)]">
                        <img
                          className="rounded-lg"
                          alt="img"
                          src={`${process.env.REACT_APP_FILES_HOST}/${runningData.questionILink}`}
                        />
                      </div>
                      <div className="mt-8 flex flex-col justify-center items-center self-center">
                        {runningData.marksPerQuestions.map((d, index) => (
                          <UploadAnswerNew
                            uploadEndpoint="/api/student/bothsubmitstudentscript"
                            exam_code={runningData.examId}
                            totalQuestions={runningData.totalQuestions}
                            marksPerQuestions={d}
                            index={index + 1}
                            token={TOKEN}
                            key={index}
                          ></UploadAnswerNew>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Skeleton count={5} height={128}></Skeleton>
                  )}
                  <div className="text-center my-4 max-w-sm mx-auto">
                    <button
                      className="btn-hover text-white pr-2 py-3 rounded-md"
                      onClick={handleSubmitBothWritten}
                    >
                      Submit Answer
                      <span className="btn-hover_icon">
                        <RightArrow />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          {params.exam_variation === 'mcq-special' &&
            examVariation === 'mcq' && (
              <div className="rounded-xl">
                {/* {examData && (<div className="flex btn-theme rounded-t-xl py-1 justify-center">
                <span className="text-2xl font-bold capitalize text-white">{types[examData.examData.examType]}</span>
              </div>)}*/}
                {examData && timer > 0 && (
                  <div className="bg-white sticky top-0 z-50">
                    <CountDownTwo
                      date={timer}
                      completedAction={handleSubmitMcqSpecial}
                    />
                  </div>
                )}
                {/* {examData && <ExamInfoDetails examInfos={examData.examData} variationType={examVariation} />} */}
                {runningData ? (
                  runningData.map((subject, index) => (
                    <div key={index}>
                      <h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white rounded-t-md uppercase">
                        {subject.subjectName}
                      </h2>
                      <div
                        className="bg-white px-6 pt-6 border-4 rounded-b-xl border-orange-600 mb-4"
                        key={index}
                      >
                        {subject.questions.map((question, idx) => (
                          <McqQuestionSpecial
                            question={question}
                            subjectId={subject.subjectId}
                            subjectIndex={index}
                            index={idx}
                            answeredIndex={subject.answeredOptions[idx]}
                            key={idx}
                            handleSubmitMcqSpecial={
                              handleQuestionSelectMcqSpecial
                            }
                          />
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <Skeleton count={5} height={128}></Skeleton>
                )}
                <div className="text-center my-4 max-w-sm mx-auto">
                  <button
                    className="btn-hover text-white pr-2 py-3 rounded-md"
                    onClick={handleSubmitMcqSpecial}
                  >
                    Submit Answer
                    <span className="btn-hover_icon">
                      <RightArrow />
                    </span>
                  </button>
                </div>
              </div>
            )}
          {params.exam_variation === 'special' && examVariation === 'mcq' && (
            <div className="rounded-xl">
              {/* {examData && (<div className="flex btn-theme rounded-t-xl py-1 justify-center">
                <span className="text-2xl font-bold capitalize text-white">{types[examData.examData.examType]}</span>
              </div>)}*/}
              {examData && timer > 0 && (
                <div className="bg-white sticky top-0 z-50">
                  <CountDownTwo
                    date={timer}
                    completedAction={handleSubmitSpecialMcq}
                  />
                </div>
              )}
              {/* {examData && <ExamInfoDetails examInfos={examData.examData} variationType={examVariation} />} */}
              {runningData ? (
                runningData.map((subject, index) => (
                  <>
                    <h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white rounded-t-md uppercase">
                      {subject.subjectName}
                    </h2>
                    <div
                      className="bg-white px-6 pt-6 border-4 rounded-b-xl border-orange-600 mb-4"
                      key={index}
                    >
                      {subject.questions.map((question, idx) => (
                        <QuestionSpecial
                          question={question}
                          subjectId={subject.subjectId}
                          subjectIndex={index}
                          index={idx}
                          answeredIndex={subject.answeredOptions[idx]}
                          key={idx}
                          handleSubmitSpecialMcq={
                            handleQuestionSelectSpecialMcq
                          }
                        />
                      ))}
                    </div>
                  </>
                ))
              ) : (
                <Skeleton count={5} height={128}></Skeleton>
              )}
              <div className="text-center my-4 max-w-sm mx-auto">
                <button
                  className="btn-hover text-white pr-2 py-3 rounded-md"
                  onClick={handleSubmitSpecialMcq}
                >
                  Submit Answer
                  <span className="btn-hover_icon">
                    <RightArrow />
                  </span>
                </button>
              </div>
            </div>
          )}
          {params.exam_variation === 'special' &&
            examVariation === 'written' && (
              <div className="rounded-xl">
                <div className="flex btn-theme rounded-t-xl py-1 justify-center">
                  <span className="text-2xl font-bold capitalize text-white">
                    Special Exam
                  </span>
                </div>
                {runningData && timer > 0 && (
                  <div className="bg-white sticky top-0 z-50">
                    <CountDownTwo
                      date={timer}
                      completedAction={handleSubmitSpecialWritten}
                    />
                  </div>
                )}
                {examData && (
                  <div className="py-0 mx-6 flex justify-between border-2 border-gray-500 rounded-xl">
                    <span className="pl-2">
                      Written:{' '}
                      <span className="font-bold text-title-2">
                        {examData.totalMarks}
                      </span>
                    </span>
                    <span className="pr-2">
                      Time:{' '}
                      <span className="font-bold text-title-2">
                        {examData.duration} Min
                      </span>
                    </span>
                  </div>
                )}
                {runningData ? (
                  <>
                    {runningData.map((d, index) => (
                      <div key={index}>
                        <h2 className="bg-orange-600 text-2xl font-bold text-center pt-1 text-white rounded-t-md uppercase">
                          {d.subjectName}
                        </h2>
                        <div className="border-4 rounded-b-xl bg-white border-orange-600 mb-6">
                          <div className="mb-3 shadow-[0px_0px_4px_2px_rgba(275,75,0,0.75)] p-4">
                            <img
                              className="rounded-lg"
                              alt="img"
                              src={`${process.env.REACT_APP_FILES_HOST}/${d.writtenILink}`}
                            />
                          </div>
                          <div className="mt-8 flex flex-col justify-center items-center self-center px-4">
                            {d.marksPerQuestion.map((m, idx) => (
                              <UploadAnswerSpecialNew
                                uploadEndpoint="/api/special/submitstudentscript"
                                exam_code={params.exam_code}
                                subjectId={d.subjectId}
                                index={idx + 1}
                                marksPerQuestion={m}
                                token={TOKEN}
                                key={idx}
                              ></UploadAnswerSpecialNew>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <Skeleton count={5} height={128}></Skeleton>
                )}
                <div className="text-center my-4 max-w-sm mx-auto">
                  <button
                    className="btn-hover text-white pr-2 py-3 rounded-md"
                    onClick={handleSubmitSpecialWritten}
                  >
                    Submit Answer
                    <span className="btn-hover_icon">
                      <RightArrow />
                    </span>
                  </button>
                </div>
              </div>
            )}
        </div>
      </div>
      <Suspense fallback={null}>
        <Modal2 {...error} />
      </Suspense>
      <Suspense fallback={null}>
        <Modal {...result} />
      </Suspense>
    </>
  )
}

export default OngoingExam
