// import { Suspense, lazy } from 'react';

import Layout from "./pages/layouts/Layout";
import HeaderLessLayout from "./pages/layouts/HeaderLessLayout";

import Home from "./pages/Home";
import History from "./pages/History";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import LeaderBoard from "./pages/LeaderBoard";
import ChangePassword from "./pages/ChangePassword";
import MissedExams from "./pages/MissedExams";
import AllExams from "./pages/AllExams";
import ExamBySubject from "./pages/ExamBySubject";

import BeforeStart from './pages/exams/BeforeStart';
import ExamRules from './pages/exams/ExamRules';
import ExamSolution from './pages/exams/ExamSolution';
import ExamSolutionWritten from './pages/exams/ExamSolutionWritten';
import OngoingExam from './pages/exams/OngoingExam';

import Login from "./pages/auth/Login";
import OldLogin from './pages/auth/OldLogin';
import RetakeExam from "./pages/exams/RetakeExam";
import RetakeMcqSpecial from "./pages/exams/RetakeMcqSpecial";

// Free Exam Components
import FreeBeforeStart from './pages/free_exams/BeforeStart';
import FreeExamRules from './pages/free_exams/ExamRules';
import FreeOngoingExam from './pages/free_exams/OngoingExam';
import LandingPage from "./pages/LandingPage";

export const pages = [
  {
    element: Layout,
    children: [
      {
        path: "/home",
        element: Home,
      },
      {
        path: "/history",
        element: History,
      },
      {
        path: "/profile",
        element: Profile,
      },
      {
        path: "/leaderboard",
        element: LeaderBoard,
      },
      {
        path: "/:studentId/edit-profile",
        element: EditProfile,
      },
      {
        path: "/change-password",
        element: ChangePassword,
      },
      {
        path: "/missed-exams",
        element: MissedExams,
      },
      {
        path:"exam-list/:type/all",
        element: AllExams,
      },
      {
        path:"exam-by-subject/:sId/:variation",
        element: ExamBySubject,
      }
    ],
  },
  {
    element: HeaderLessLayout,
    children: [
      {
        path:'/',
        element: LandingPage
      },
      {
        // element: Login,
        element: OldLogin,
        path: "/login",
      },
      {
        path:"/exam/:exam_code/before-start/:exam_variation",
        element: BeforeStart,
      },
      {
        path:"/exam/:exam_code/rules/:exam_variation",
        element: ExamRules,
      },
      {
        path:"/exam/:exam_code/ongoing/:exam_variation",
        element: OngoingExam,
      },
      {
        path:"/exam/:exam_code/retake",
        element: RetakeExam,
      },
      {
        path:"/exam/:exam_code/mcq-special-retake",
        element: RetakeMcqSpecial,
      },
      {
        path:"/exam/:exam_code/solution",
        element: ExamSolution,
      },
      {
        path:"/exam/:exam_code/writtensolution",
        element: ExamSolutionWritten,
      },
      // {
      //   path:"/free_exam/before-start",
      //   element: FreeBeforeStart,
      // },
      // {
      //   path:"/free_exam/rules",
      //   element: FreeExamRules,
      // },
      // {
      //   path:"/free_exam/ongoing",
      //   element: FreeOngoingExam,
      // }
    ],
  },
];